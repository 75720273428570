import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import {
  Profile,
  urls,
} from '@motional-cc/fe/interface/api/user-profile-service';
import { env } from './config';

const API_SCOPE_PREFIX = 'read:api_';
const PROFILE_URL = `https://user-profile.${env}.motional.cc${urls.Profile.GetProfile()}`;

async function fetchUserScopes(accessToken: string) {
  const response = await fetch(PROFILE_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status !== 200) {
    throw new Error(`Failed to fetch user scopes: ${response.status}.`);
  }
  const data: Profile.GetProfile.ResponseBody = await response.json();

  if (!data.result.scopes) {
    throw new Error('User scopes not found.');
  }

  return (
    data.result.scopes
      .filter((scope: string) => scope.includes(API_SCOPE_PREFIX))
      .map((scope: string) => scope.replace(API_SCOPE_PREFIX, ''))
      .sort((scopeA: string, scopeB: string) => scopeA.localeCompare(scopeB)) ??
    []
  );
}

/**
 * Returns all scopes related to read access to the API.
 * @returns {string[]}
 */
function useScopes() {
  const { authState } = useOktaAuth();
  const [scopes, setScopes] = useState<string[]>([]);
  const accessToken = authState?.accessToken?.accessToken;

  useEffect(
    function requestScopes() {
      if (!accessToken) return;

      fetchUserScopes(accessToken).then(setScopes);
    },
    [accessToken],
  );

  return scopes;
}

export default useScopes;
